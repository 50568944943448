:root {
    --colors-selected: #e3c050;
    --colors-black: #000;
    --colors-white: #ffffff;
    --color-default-light: #373737;
    --color-default-dark: #454648;
    --bs-secondary-text-emphasis: #8c8e95;
    --bs-body-bg: #131313;
    --bs-body-color: #fff;
    --colors-primaryLight: #2b2b2b;
    --hms-ui-colors-backgroundDefault: #1f1f1f;
    --hms-ui-colors-borderDefault: #373737;
    --hms-ui-colors-yellow: #e3c050;
    --hms-ui-colors-secondary: #8c8e95;
    --hms-ui-colors-textPrimary: #e9e9e9;
    --hms-ui-colors-mainBg: #1F1F1F;
    --hms-ui-colors-surfaceLight: #131313;
    --hms-ui-colors-gray: #575757;
    --hms-ui-colors-light: #C0C0C0;
}