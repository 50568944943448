.dashboard-event-table {
    td {
        padding: 10px;

        &:not(:first-child) {
            white-space: nowrap;
        }
    }
}

.react-tagsinput {
    width: 100%;
    background-color: var(--colors-black) !important;
    padding: 8px !important;
    border-radius: 6px;
    min-height: 116px;
    border: 1px solid var(--color-default-light) !important;

    &--focused {
        border-color: var(--colors-selected) !important;
    }

    span:first-of-type {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
    }

    &-tag {
        background: var(--color-default-light) !important;
        border: none !important;
        margin: 0;
        padding: 6px 8px;
        color: #fff !important;
        font-size: 16px !important;
        font-weight: normal !important;
    }

    &-remove {
        cursor: pointer;
        font-weight: 700;
        color: #fff !important;
        text-decoration: none !important;
    }

    &-input {
        width: 200px !important;
    }
}

.event-participant {
    &-sample {
        font-size: 14px;

        &-link {
            color: var(--colors-selected);
            margin-left: 5px;
            font-size: 90%;
            cursor: pointer;
        }
    }
}

.event-subscription {
    &-currentplan {
        color: var(--colors-selected);
    }
}

.custom {
    &-form {
        &-file {
            display: flex;
            cursor: pointer;
            font-size: 16px !important;
            display: flex;
            align-items: center;
            gap: 10px;

            &-input {
                display: none;
            }

            &-preview {
                width: 40px;
                height: 40px;
                border: 1px solid var(--color-default-light);
                background: var(--colors-black);
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -ms-border-radius: 4px;
                -moz-border-radius: 4px;
                -o-border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                img {
                    object-fit: cover;
                }
            }
        }
    }
}