html,
body {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-color: var(--hms-ui-colors-backgroundDefault) !important;
}

.managepro6ix {
    width: 100%;

    &-layout {
        width: 100%;
        --bs-bg-opacity: 1;
        background-color: var(--hms-ui-colors-backgroundDefault);

        &-page {
            overflow: auto;
            margin: 116px 0px 0px 0px;
            width: 100%;
            height: calc(100vh - 116px);

            &-content {
                padding-left: 20px;
                padding-right: 10px;

                &-header {
                    width: 100%;
                    padding: 20px 0px;
                    display: flex;

                    &-left {}

                    &-action {
                        margin-left: auto;
                        margin-right: 0;
                    }

                    &-heading {
                        font-size: 20px;
                        font-weight: 600;
                    }


                    &-menu {
                        overflow: auto;
                        white-space: nowrap;
                        border-bottom: 1px solid var(--colors-primaryLight);

                        &-link {
                            display: inline-block;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 500;
                            color: var(--colors-white);
                            padding: 0px 8px 5px;
                            line-height: 40px;
                            text-transform: capitalize;
                            border-bottom: 2px solid transparent;
                            text-decoration: none;

                            &:not(:first-child) {
                                margin-left: 12px;

                                @media (max-width: 767px) {
                                    margin-left: 10px;
                                }
                            }

                            &.active {
                                color: var(--hms-ui-colors-yellow);
                                border-color: var(--hms-ui-colors-yellow);
                            }

                            &:hover {
                                color: var(--hms-ui-colors-yellow);
                            }
                        }
                    }
                }
            }
        }

        &-full & {
            &-page {
                margin: 0;
                height: 100vh;
            }
        }
    }

    &-btn {
        width: fit-content;
        padding: 8px 15px;
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -ms-border-radius: 4px;
        -moz-border-radius: 4px;
        -o-border-radius: 4px;
        background-color: transparent;
        color: var(--colors-white);
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        text-decoration: none;

        &-primary {
            border-color: var(--colors-selected);
            color: var(--colors-white);
            background-color: var(--colors-black);

            &:hover {
                color: var(--colors-selected);
            }
        }

        &-secondary {
            border-color: var(--color-default-light);
            color: var(--colors-white);

            &:hover {
                background-color: var(--color-default-dark);
                border-color: var(--color-default-dark);
                color: var(--colors-white);
            }
        }

        &-large {
            height: 50px;
        }

        &-medium {
            padding: 7px 10px;
            font-size: 14px;
        }

        &-sm {
            padding: 5px 10px;
            font-size: 90%;
        }

        &[expand="full"] {
            width: 100%;
        }

        &[font="16"] {
            font-size: 16px;
        }

        &[font="18"] {
            font-size: 18px;
        }
    }

    &-table {
        &-center {

            td,
            th {
                text-align: center;
            }
        }
    }
}

.m {
    &-l {
        &-5 {
            margin-left: 5px;
        }

        &-5 {
            margin-left: 5px;
        }

        &-10 {
            margin-left: 10px;
        }

        &-15 {
            margin-left: 15px;
        }

        &-20 {
            margin-left: 20px;
        }
    }

    &-r {
        &-5 {
            margin-right: 5px;
        }

        &-5 {
            margin-right: 5px;
        }

        &-10 {
            margin-right: 10px;
        }

        &-15 {
            margin-right: 15px;
        }

        &-20 {
            margin-right: 20px;
        }
    }

    &-t {
        &-5 {
            margin-top: 5px;
        }

        &-5 {
            margin-top: 5px;
        }

        &-10 {
            margin-top: 10px;
        }

        &-15 {
            margin-top: 15px;
        }

        &-20 {
            margin-top: 20px;
        }
    }

    &-b {
        &-5 {
            margin-bottom: 5px;
        }

        &-5 {
            margin-bottom: 5px;
        }

        &-10 {
            margin-bottom: 10px;
        }

        &-15 {
            margin-bottom: 15px;
        }

        &-20 {
            margin-bottom: 20px;
        }
    }
}

.custom {
    &-dialog {
        .MuiPaper-root {
            background-color: #000;
            border: 1px solid #454648;
            border-radius: 10px;
            color: var(--hms-ui-colors-white);
            padding: 15px;

            @media (max-width: 767px) {
                margin: 0;
                max-width: calc(100vw - 32px);
            }

            h2 {
                font-weight: 600;
                font-size: 20px;
            }
        }

        &-action {
            justify-content: center !important;
            gap: 10px;
        }

        &-btn {
            min-width: 120px !important;
        }

        &-full {
            .MuiPaper-root {
                width: 100%;
            }
        }
    }
}

.text {
    &-status {
        text-transform: uppercase;
    }

    &-link {
        color: var(--colors-selected);
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &-linkHover {
        color: #b6b6b6;
        cursor: pointer;

        &:hover {
            color: var(--colors-selected);
            text-decoration: underline;
        }
    }
}

.highcharts {
    &-credits {
        display: none;
    }
}

.mt {
    &-auto {
        margin-top: auto !important;
    }

    &-0 {
        margin-top: 0px !important;
    }

    &-5 {
        margin-top: 5px !important;
    }

    &-10 {
        margin-top: 10px !important;
    }

    &-15 {
        margin-top: 15px !important;
    }

    &-20 {
        margin-top: 20px !important;
    }
}

.mb {
    &-auto {
        margin-bottom: auto !important;
    }

    &-0 {
        margin-bottom: 0px !important;
    }

    &-5 {
        margin-bottom: 5px !important;
    }

    &-10 {
        margin-bottom: 10px !important;
    }

    &-15 {
        margin-bottom: 15px !important;
    }

    &-20 {
        margin-bottom: 20px !important;
    }
}

.ml {
    &-auto {
        margin-left: auto !important;
    }

    &-0 {
        margin-left: 0px !important;
    }

    &-5 {
        margin-left: 5px !important;
    }

    &-10 {
        margin-left: 10px !important;
    }

    &-15 {
        margin-left: 15px !important;
    }

    &-20 {
        margin-left: 20px !important;
    }
}

.mr {
    &-auto {
        margin-right: auto !important;
    }

    &-0 {
        margin-right: 0px !important;
    }

    &-5 {
        margin-right: 5px !important;
    }

    &-10 {
        margin-right: 10px !important;
    }

    &-15 {
        margin-right: 15px !important;
    }

    &-20 {
        margin-right: 20px !important;
    }
}