@import "../../assets/variables.scss";

.managepro6ix-layout {
    &-header {
        width: 100% !important;
        margin: 0px !important;
        background: var(--hms-ui-colors-backgroundDefault) !important;
        box-shadow: none !important;

        &-inner {
            min-height: 52px !important;
            gap: 10px;
        }

        &-menuBtn {
            background: transparent !important;
            padding: 0px !important;
            margin-right: 10px !important;

            &:hover {
                svg {
                    path {
                        &[stroke] {
                            stroke: var(--colors-white);
                        }

                        &[fill] {
                            fill: var(--colors-white);
                        }

                    }
                }
            }
        }

        &-lineV {
            width: 1px;
            height: 24px;
            background-color: var(--hms-ui-colors-borderDefault);
            margin-right: 10px;
        }

        &-logo {
            padding: 0px 5px;
            display: flex;


            &-img {
                max-width: 100%;
                max-height: 24px;
            }

            &-text {
                font-size: 13px;
                color: var(--hms-ui-colors-yellow);
                margin: auto -5px 0px -15px;
                padding-right: 5px;
                text-decoration: none;
            }
        }

        &-right {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: auto;

            @media(max-width:767px) {
                gap: 5px;
            }

            &-item {
                position: relative;

                &-btn {
                    cursor: pointer;
                    color: var(--hms-ui-colors-light);
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 125%;
                    text-align: center;
                    width: 42px;
                    height: 42px;
                    @include vendor-prefix(border-radius, 8px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid transparent;



                    svg {
                        margin: auto auto 0;
                    }

                    &-text {
                        margin-top: 5px;
                        margin-bottom: auto;
                    }

                    &.active,
                    &:hover {
                        border-color: var(--hms-ui-colors-yellow);
                        background-color: var(--hms-ui-colors-mainBg);
                        color: var(--hms-ui-colors-textPrimary);

                        svg {

                            path,
                            rect {
                                &[fill] {
                                    fill: var(--hms-ui-colors-textPrimary);
                                }

                                &[stroke] {
                                    stroke: var(--hms-ui-colors-textPrimary);
                                }
                            }
                        }
                    }

                    &-user {
                        width: 30px;
                        height: 30px;
                        @include vendor-prefix(border-radius, 50px);
                        overflow: hidden;
                        margin: auto;
                        display: flex;
                        background-color: var(--colors-primaryLight);

                        svg {
                            margin: auto;
                        }

                        img {
                            object-fit: cover;
                        }
                    }

                    &-full {
                        width: auto;
                        font-size: 16px;
                        flex-direction: row;
                        padding-left: 12px;
                        padding-right: 12px;
                        font-weight: normal;

                        svg {
                            margin: 0px 0px 0px 6px !important;
                        }

                        @media(max-width: 1024px) {
                            font-size: 14px;
                            padding-left: 6px;
                            padding-right: 6px;
                        }
                    }

                    &-full & {
                        &-text {
                            margin: 0px;
                        }
                    }
                }

                &-dropdown {
                    .MuiPaper-root {
                        @include vendor-prefix(border-radius, 11px);
                        border: 1px solid var(--hms-ui-colors-borderDefault);
                        background: var(--hms-ui-colors-mainBg);
                        box-shadow: 0px 4px 50px 0px var(--hms-ui-colors-surfaceLight);
                        margin-top: 14px;
                        min-width: 200px;
                        max-width: 90%;
                        max-height: calc(100% - 105px);
                    }

                    &-language {
                        &-header {
                            color: var(--hms-ui-colors-gray);
                            padding: 8px 8px;
                            font-size: 15px;
                        }

                        .MuiPaper-root {
                            width: 500px;
                            padding: 0px 8px;
                        }

                        &-inner {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        &-item {
                            color: var(--hms-ui-colors-light) !important;
                            font-size: 14px !important;
                            width: 33.33%;
                            @include vendor-prefix(border-radius, 8px !important);
                            padding: 4px !important;

                            &-check {
                                width: 15px;

                                svg {
                                    width: 10px;
                                    height: auto;
                                }
                            }

                            &.active {
                                color: var(--hms-ui-colors-yellow) !important;
                            }

                            @media (max-width: 767px) {
                                font-size: 13px !important;
                            }
                        }
                    }

                    &-apps {
                        &-header {
                            padding: 12px 20px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 135%;
                            color: var(--hms-ui-colors-textPrimary);
                            border-bottom: 1px solid var(--colors-primaryLight);
                        }

                        &-list {
                            padding-left: 8px !important;
                            padding-right: 8px !important;

                            &-item {
                                @include vendor-prefix(border-radius, 11px !important);
                                gap: 10px !important;

                                span {
                                    font-size: 16px;
                                    color: var(--hms-ui-colors-yellow);
                                }

                                p {
                                    font-size: 14px;
                                    color: var(--hms-ui-colors-secondary);
                                }

                                @media (max-width: 767px) {
                                    gap: 5px;

                                    span {
                                        font-size: 15px;
                                    }

                                    p {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }

                    &-users {
                        &-header {
                            padding: 12px 20px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 135%;
                            color: var(--hms-ui-colors-textPrimary);
                            border-bottom: 1px solid var(--colors-primaryLight);
                        }

                        &-list {
                            padding: 0px 8px !important;

                            &-divider {
                                height: 1px;
                                background-color: var(--colors-primaryLight);
                                margin: 8px -8px;
                            }

                            &-item {
                                @include vendor-prefix(border-radius, 11px !important);
                                gap: 10px !important;
                                font-size: 14px;
                                color: var(--hms-ui-colors-light) !important;

                                span {
                                    font-size: 14px;
                                }

                                &:hover {
                                    color: var(--hms-ui-colors-textPrimary) !important;
                                }
                            }
                        }
                    }
                }

                &-accounts {
                    padding-bottom: 50px;

                    &-btn {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        position: relative;
                        padding-right: 10px;
                        max-width: 230px;
                        cursor: pointer;
                        color: var(--hms-ui-colors-light);
                        font-size: 14px;
                        font-weight: 600;
                        border: 1px solid transparent;
                        height: 42px;
                        @include vendor-prefix(border-radius, 8px);
                        padding: 0px 10px;

                        @media (max-width: 767px) {
                            padding: 0px 5px;
                            gap: 5px;
                        }

                        &-logo {
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: var(--hms-ui-colors-borderDefault);
                            border: 1px solid var(--hms-ui-colors-gray);
                            @include vendor-prefix(border-radius, 50%);
                            overflow: hidden;
                            flex: none;

                            img {
                                object-fit: cover;
                            }
                        }

                        &-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media (max-width: 767px) {
                                max-width: 70px;
                                font-size: 13px;
                            }
                        }

                        &.active,
                        &:hover {
                            border-color: var(--hms-ui-colors-yellow);
                            background-color: var(--hms-ui-colors-mainBg);
                            color: var(--hms-ui-colors-textPrimary);

                            svg path {
                                &[fill] {
                                    fill: var(--hms-ui-colors-textPrimary);
                                }

                                &[stroke] {
                                    stroke: var(--hms-ui-colors-textPrimary);
                                }
                            }
                        }
                    }

                    &-list {
                        padding: 0px 8px !important;
                        margin-bottom: 10px !important;

                        &-loader {
                            padding: 20px;
                            text-align: center;
                        }

                        &-item {
                            @include vendor-prefix(border-radius, 11px !important);
                            gap: 10px !important;
                            font-size: 14px;
                            color: var(--hms-ui-colors-light) !important;
                            padding: 8px 10px !important;

                            &-logo {
                                width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: var(--hms-ui-colors-borderDefault);
                                border: 1px solid var(--hms-ui-colors-gray);
                                @include vendor-prefix(border-radius, 50%);
                                overflow: hidden;
                                flex: none;

                                img {
                                    object-fit: cover;
                                }
                            }

                            &-name {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &-check {
                                margin-left: auto;

                                svg {
                                    width: 14px;
                                    height: auto;
                                }
                            }

                            &:hover {
                                color: var(--hms-ui-colors-textPrimary) !important;
                            }
                        }
                    }

                    &-footer {
                        padding: 12px 20px;
                        font-size: 16px;
                        font-weight: 700;
                        color: var(--hms-ui-colors-light);
                        border-top: 1px solid var(--colors-primaryLight);
                        position: sticky;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        cursor: pointer;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        right: 0;
                        justify-content: center;
                        gap: 5px;
                        background-color: var(--hms-ui-colors-backgroundDefault);

                        &:hover {
                            color: var(--hms-ui-colors-yellow);

                            svg path {
                                &[fill] {
                                    fill: var(--hms-ui-colors-yellow);
                                }

                                &[stroke] {
                                    stroke: var(--hms-ui-colors-yellow);
                                }
                            }
                        }
                    }
                }
            }

        }

        &-bottom {
            width: 100%;
            padding: 16px 24px 0;
            display: flex;

            @media (max-width: 767px) {
                padding: 16px 16px 0;
            }

            &-menu {
                overflow: auto;
                white-space: nowrap;
                border-bottom: 1px solid var(--colors-primaryLight);

                &-link {
                    display: inline-block;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--hms-ui-colors-secondary);
                    padding: 0px 8px 5px;
                    line-height: 40px;
                    border-bottom: 2px solid transparent;
                    text-decoration: none;
                    cursor: pointer;

                    svg {
                        margin-left: 6px;
                    }

                    &:not(:first-child) {
                        margin-left: 12px;

                        @media (max-width: 767px) {
                            margin-left: 10px;
                        }
                    }

                    &.active {
                        color: var(--hms-ui-colors-textPrimary);
                        border-color: var(--hms-ui-colors-yellow);
                    }

                    &:hover {
                        color: var(--hms-ui-colors-textPrimary);
                    }

                    &-dropdown {
                        .MuiPaper-root {
                            @include vendor-prefix(border-radius, 11px);
                            border: 1px solid var(--hms-ui-colors-borderDefault);
                            background: var(--hms-ui-colors-mainBg);
                            box-shadow: 0px 4px 50px 0px var(--hms-ui-colors-surfaceLight);
                            margin-top: 14px;
                            min-width: 200px;
                            max-width: 90%;
                            max-height: calc(100% - 105px);
                        }

                        &-users {
                            &-header {
                                padding: 12px 20px;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 135%;
                                color: var(--hms-ui-colors-textPrimary);
                                border-bottom: 1px solid var(--colors-primaryLight);
                            }

                            &-list {
                                padding: 0px 8px !important;

                                &-divider {
                                    height: 1px;
                                    background-color: var(--colors-primaryLight);
                                    margin: 8px -8px;
                                }

                                &-item {
                                    @include vendor-prefix(border-radius, 11px !important);
                                    gap: 10px !important;
                                    font-size: 14px;
                                    color: var(--hms-ui-colors-light) !important;

                                    span {
                                        font-size: 14px;
                                    }

                                    &:hover {
                                        color: var(--hms-ui-colors-textPrimary) !important;
                                    }

                                    &.active {
                                        color: var(--hms-ui-colors-yellow) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-sidebar {
        &.MuiDrawer-docked {
            &>.MuiPaper-root {
                height: calc(100vh - 67px);
                background: var(--hms-ui-colors-backgroundDefault) !important;
                top: 67px !important;
            }
        }
    }
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    background-color: #1f1f1f !important;
}

.css-1cm696p-MuiTypography-root {
    margin-bottom: '1px solid #454648' !important;
}




@media screen and (max-width: 480px) {
    .managepro6ix-layout .css-386zj2-MuiDrawer-docked {
        display: none;
    }
}