.managepro6ix {
    &-loginPopup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 780px) {
            width: 90%;
        }

        &-inner {
            color: var(--colors-white);
            background-color: #1f1f1f;
            border: "2px solid #000";
            padding: 24px;
            border-radius: 6px;
            border: 1px solid #454648;
        }

        &-logo {
            text-align: center;
            margin: 0px 0px 5px;

            img {
                max-width: 100%;
            }
        }

        &-heading {
            font-size: 24px;
            text-align: center
        }

        &-text {
            font-size: 14px;
            text-align: center;
            color: var(--bs-secondary-text-emphasis)
        }

        &-input {
            color: var(--colors-white);
            width: 100%;
        }

        button {
            width: 100%;
            margin-top: 20px;
        }

        &-or {
            position: relative;
            text-align: center;
            color: #575757;
            font-size: 14px;
            margin: 25px 0px;

            &-text {
                &::before {
                    left: 0;
                    content: "";
                    height: 1px;
                    background-color: #373737;
                    position: absolute;
                    top: 50%;
                    width: 45%;
                }

                &::after {
                    right: 0;
                    content: "";
                    height: 1px;
                    background-color: #373737;
                    position: absolute;
                    top: 50%;
                    width: 45%;
                }

            }
        }


        &-email {
            width: 100%;

            &-text {
                font-size: 13px;
                color: rgb(255 255 255 / 60%);
            }

            &-btn {
                padding: 10px 20px;
                text-align: center;
                border: 1px solid var(--hms-ui-colors-twinYellow);
                border-radius: 6px;
                background-color: var(--hms-ui-colors-black);
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                height: 48px;
                text-transform: uppercase;

            }

            &-msg {
                text-align: center;
                font-size: 14px;
                min-height: 48px;
            }

            &-link {
                text-align: center;
                cursor: pointer;
                font-size: 14px;
                padding: 5px 0px;
                color: var(--colors-selected);
                text-decoration: underline;
            }


        }





    }
}