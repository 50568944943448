.viewButton {
    width: fit-content;
    padding: 4px 10px;
    height: 36px;
    border: 1px solid rgb(223, 192, 35);
    border-radius: 4px;
    background-color: black;
    color: white;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



.viewButton:hover {
    color: rgb(223, 192, 35)
}

/* Input */

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
    color: #454648 !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    border-radius: 5px;
    background: #131313;
    width: 100%;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    /* border-color: #454648 !important; */
    /* border: 1px solid #454648 !important; */
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffca28 !important;
    border-width: 1px !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #ffca28 !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:hover {
    /* border: 1px solid #454648 !important; */
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    color: #454648 !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    color: #fff !important;
    height: 40px !important;
}


/* selection Dropdown */
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #454648 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover {
    border-color: #ffca28 !important;
    background: #131313 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: #fff !important;
}

.css-bpeome-MuiSvgIcon-root-MuiSelect-icon,
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    color: #fff !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    background-color: #2a2a2a !important;
    color: #fff !important;

}

/* dropdown */

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #515151 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    color: white !important;
}


/* Datepicker */
.datepickerdatk {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.datepickerdatk label {
    font-size: 12px;
    color: #515151;
    font-weight: 500;
}

.datepickerdatk input {
    background: #131313;
    border: 1px solid #454648;
    height: 54px;
    border-radius: 4px;
    color: white;
    padding: 10px;
    width: 100%;
}

.MuiInputBase-input {
    color: white !important;
}

.datepickerdatk input:focus {
    outline: 1px solid #ffca28;
}

.datepickerdatk .react-datepicker {
    background: #131313;
    color: #fff !important;
}

.datepickerdatk .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #fff;
}

.datepickerdatk .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: transparent;
    color: #ffca28;
}

.datepickerdatk .react-datepicker__header {
    background: #131313;
    border-bottom: none;
}

.datepickerdatk .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff;
}

.datepickerdatk .react-datepicker__day--outside-month {
    color: #131313;
}

.react-datepicker__day:hover {
    background-color: #2a2a2a;
}

.react-datepicker-popper {
    z-index: 2;
}