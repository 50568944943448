/* //maintable */
.datatable .css-11mde6h-MuiPaper-root {
    background-color: transparent;
    color: white;
    box-shadow: none;
}

/* th extra class */
.datatable .tss-ylo811-MUIDataTableSelectCell-headerCell {
    background-color: transparent;
}

/* responsive td th hide */
.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon:nth-last-of-type(2) {
    display: none;
}

.datatable th {
    background-color: transparent;
    color: rgb(223, 192, 35);
    white-space: nowrap;
    font-weight: 700;
    padding: 10px 10px;
    font-family: "Montserrat",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    font-size: 10.5px !important;
    text-transform: uppercase;
}

.datatable td {
    color: #b6b6b6;
    padding: 16px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsiss;
    border-bottom: 1px solid rgba(182, 182, 182, 0.7);
}



.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
    padding: 0px;
}

/* paginator */
.css-jtlhu6-MuiTablePagination-root {
    color: white !important;
}

.datatable .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin-right: 5px !important;
}

.datatable .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
    margin-left: 0px !important;
}

/* paginator arrows */
.datatable .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled,
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    color: white !important;
    padding: 0 !important;
}

/* paginator text */
.css-pdct74-MuiTablePagination-selectLabel,
.css-levciy-MuiTablePagination-displayedRows {
    margin-bottom: 3px !important;
}

/* search bar */
.css-l4u8b9-MuiInputBase-root-MuiInput-root:before,
.css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
}



.tss-733vlj-MUIDataTableSearch-searchText {
    flex: none !important;
}

/* search bar input */
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    width: 180px !important;
    color: white !important;
}

/* .css-i4bv87-MuiSvgIcon-root {
    display: none !important;
} */

.css-l4u8b9-MuiInputBase-root-MuiInput-root {
    border: 1px solid rgb(223, 192, 35) !important;
    border-radius: 4px !important;
    height: 36px !important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    padding: 10px !important;
    width: 132px !important;

}

.tss-1ufdzki-MUIDataTableSearch-main {
    width: 100% !important;
    display: flex;
    justify-content: right;

}

.tss-1fz5efq-MUIDataTableToolbar-left {
    width: 100%;
}

/* hode searchicon */
.tss-1h5wt30-MUIDataTableSearch-searchIcon {
    display: none !important;
}

.tss-1fz5efq-MUIDataTableToolbar-left {
    padding: 0 !important;
}

.tss-qbo1l6-MUIDataTableToolbar-actions {
    text-align: right;
}